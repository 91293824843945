@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Gin;
    src: url('../fonts/Gin-Regular.ttf') format("truetype");
}

@font-face {
    font-family: GinRough;
    src: url('../fonts/Gin-Rough.ttf') format("truetype");
}

@font-face {
    font-family: DIN2014;
    src: url('../fonts/DIN2014-Regular.ttf') format("truetype");
}

body {
    margin: 0;
    font-family: Gin, DIN2014, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}

a {
    -webkit-touch-callout: none !important;
}

input {
    user-select: auto;
}

.background-img-cover {
    background-repeat: no-repeat;
    background-size: cover;
}

.background-img-contain {
    background-repeat: no-repeat;
    background-size: contain;
}

.barber-tools-background {
    @extend .background-img-cover;
    background-image: url('../images/barber-tools.png');
    background-color: #231D19;
}

@media (min-width: 1370px) {
    .barber-tools-background {
    }
}

.container-wide {
    width: 100%;
    max-width: 1730px;
    padding-left: 10rem;
    padding-right: 10rem;
}

.max-w-landing-text {
    max-width: 930px;
}

.separator {
    height: 1px;
    background-image: url("../images/landing/horizontal-rule.svg");
    background-repeat: repeat-x;
}

@media (min-width: 1536px) {
    .separator-container {
        padding-right: 118px;
    }
}

.countdown {
    @extend .background-img-contain;
    background-image: url('../images/border-gold-mobile.svg');
    background-position-x: center;
}

@media (min-width: 640px) {
    .countdown {
        background-image: url('../images/border-gold.svg');
    }
}

.timer-label {
    padding-right: 0.4em;
}


.cutting-hair-image {
    @extend .background-img-cover;
    background-image: url('../images/landing/cutting-hair.png');
    background-position-x: 75%;
}

.overlay-x-gradient {
    background: rgb(40,30,24);
    background: linear-gradient(90deg, rgba(35,29,25,1) 0%, rgba(35,29,25,0) 100%);

    width: 50%;
}

.overlay-y-gradient {
    background: rgb(40,30,24);
    background: linear-gradient(0deg, rgba(35,29,25,1) 0%, rgba(35,29,25,0) 100%);

    height: 50%;
}

.landing {
    color: #FFF;

    .welcome-section {
        @extend .barber-tools-background;

    }

    .image-fade-out {
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        @media only screen and (min-width: 768px) {
            mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

.prize-card {
    @extend .background-img-cover;

    &.place-1 {
        background-image: url('../images/first-place-background.png');

        .prize-box {
            @extend .background-img-contain;
            background-image: url('../images/gold.svg');
        }
    }

    &.place-2 {
        background-image: url('../images/second-place-background.png');

        .prize-box {
            @extend .background-img-contain;
            background-image: url('../images/silver.svg');
        }
    }

    &.place-3 {
        background-image: url('../images/third-place-background.png');

        .prize-box {
            @extend .background-img-contain;
            background-image: url('../images/bronze.svg');
        }
    }
}

.entry-fee {
    @extend .background-img-contain;
    background-image: url('../images/border-gold.png');
}

.step-card {
    background-color: #0F0C0B;

    .inner-step-card {
        @extend .background-img-contain;
        background-image: url('../images/border-gold.png');

        &.square {
            background-image: url('../images/border-golden-square.png');
        }
    }
}

.swiper-button-prev {
    @extend .background-img-contain;
    background-image: url('../icons/arrow-left.png');

    &::after {
        display: none;
    }
}

.swiper-button-next {
    @extend .background-img-contain;
    background-image: url('../icons/arrow-right.png');

    &::after {
        display: none;
    }
}

.header {
    background-color: #231D19;
    @supports (backdrop-filter: blur(12px)) {
        background-color: unset;
        backdrop-filter: blur(12px);
    }
}

.how-it-works {
    @extend .background-img-cover;
    background-image: url('../images/cutting-hair-background.png');
}

.signup {
    @extend .barber-tools-background;
}

.image-preview {
    @extend .background-img-cover;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.checkout {
    @extend .barber-tools-background;
}

.success {
    @extend .barber-tools-background;
}

#teaser {
    .teaser-video {
        position: absolute;
    }

    .countdown {
        @extend .background-img-contain;
        color: #FFF;
        background-image: url('../images/border-gold.svg');
        background-position-x: center;
        height: 180px;
        span {
            text-align: center;
            font-size: 1.5rem;
            font-weight: 300;
            max-width: 15rem;
            @media only screen and (max-width: 768px) {
                font-size: 1rem;
                top: -5rem;
            }
            @media only screen and (min-width: 768px ) and (max-width: 1024px) {
                top: 0;
            }
        }
        div:first-of-type {
            @media only screen and (max-width: 768px) {
                padding: 0.5rem;
            }
        }
    }

    .image-fade-out {
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 99%, rgba(0, 0, 0, 0) 100%);
        @media only screen and (min-width: 768px) {
            mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        }
    }

    video {
        object-position: 85%;
        @media only screen and (max-width: 768px) {
            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 100%);
        }
    }

    #image-overlay {
        @media only screen and (min-width: 768px ) and (max-width: 1024px) {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}






